import styled from "styled-components";
import { isEmpty } from "lodash";
import { Fragment } from "react";

export const getStyledIcon = (icon, color = "#3D79F2", type = "fill", styles = undefined) => {
    return (
        <Fragment>
            <SVGIcon $color={color} $type={type} $sty={styles}>
                {icon}
            </SVGIcon>
        </Fragment>
    );
};

const SVGIcon = styled.span.attrs((props) => ({}))`
    > svg {
        ${(props) => (!isEmpty(props.$sty) ? props.$sty : "")};
        > path {
            ${(props) => (props.$type == "stroke" ? `stroke: ${props.$color}` : "")}
            ${(props) => (props.$type == "fill" ? `fill: ${props.$color}` : "")}
        }
    }
`;
